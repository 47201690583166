.login-logo {
	max-width: 200px;
	margin-bottom: 30px;
}
.form ion-content, .form ion-list, .form ion-item {
	--background: transparent!important;
}
/*
.gradient-background {
	background: rgb(18,38,59) !important;
	background: -moz-linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	background: -webkit-linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	background: linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#12263b",endColorstr="#0b1a2a",GradientType=1) !important;
}*/
.form-box {
	width: 100%;
	max-width: 512px;
	border-radius: 5px;
	overflow: hidden;
	margin: 0 auto;
	background-color: #fff;
}
.wrapper {
	padding: 10px!important;
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
}
.d-flex-center {
	width: 100%;
}
.form ion-list {
	padding: 15px 10px 25px 10px!important;
}
.light-background {
	background-color: rgba(255, 255, 255, .1)!important;
}
.text-style {
	margin: 20px 20px 0px 20px;
	color: rgb(89, 89, 89);
}