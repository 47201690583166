

.promotion .bg {
  float:right;
}

@media (max-width: 860px) {
  .promotion {
    margin-top:-65px;
  }
  .promotion .content {
    position:absolute;
    bottom:10%;
    left:15px;
    color:white;
  }
  .promotion h1 {
    font-size:1em;
    font-weight:bolder;
  }  
  .promotion .description {
    font-size:0.7em;
    font-weight:bolder;
    width: 50%;
    display:none;
  }
  .promotion ion-button {
    margin-top: 1.5em;
  }
}

@media (min-width: 860px) {
  .promotion {
    margin-top:-85px;
  }
  .promotion ion-button {
    margin-top: 3em;
  }
  .promotion .content {
    position:absolute;
    bottom:10%;
    left:15px;
    color:white;
  }
  .promotion h1 {
    font-size:2.0em;
    font-weight:bolder;
  }
  .promotion .description {
    font-size:1em;
    font-weight:bolder;
    width: 50%;
  }
}