@import "~@ionic/react/css/ionic.bundle.css";

html, body, h1, p {
  --ion-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif  !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}


.datepicker {
  height: 100px
}

ion-col.md {
  padding: 0px!important;
}
