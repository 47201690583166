:root {
  --ion-color-primary: #41a337;
  --ion-color-primary-rgb: 254, 40, 81;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #e02347;
  --ion-color-primary-tint: #d8f8d4;

  --ion-color-secondary: #5ac8fa;
  --ion-color-secondary-rgb: 90, 200, 250;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #4fb0dc;
  --ion-color-secondary-tint: #cde8f5;

  --ion-color-tertiary: #5856d6;
  --ion-color-tertiary-rgb: 88, 86, 214;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4d4cbc;
  --ion-color-tertiary-tint: #d3d2f4;

  --ion-color-success: #34c759;
  --ion-color-success-rgb: 52, 199, 89;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #2eaf4e;
  --ion-color-success-tint: #48cd6a;

  --ion-color-warning: #ffcc00;
  --ion-color-warning-rgb: 255, 204, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0b400;
  --ion-color-warning-tint: #ffd11a;

  --ion-color-danger: #ff3b30;
  --ion-color-danger-rgb: 255, 59, 48;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e0342a;
  --ion-color-danger-tint: #ff4f45;

  --ion-color-dark: #000;
  --ion-color-dark-rgb: 28, 28, 39;
  --ion-color-dark-contrast: #6fb25a;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #191922;
  --ion-color-dark-tint: #d0d0da;

  --ion-color-medium: #8e8e93;
  --ion-color-medium-rgb: 142, 142, 147;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #7d7d81;
  --ion-color-medium-tint: #99999e;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-background-color: #fff;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-text-color-rgb: #fff;
  --ion-text-color: #fff;
  /* --ion-background-color: #243645; */
  --ion-background-color: black;
}

a {
  text-decoration: none;
}

.md {
  --ion-toolbar-height: 56px;
  --platform-opacity: 1;
}

.ios {
  --ion-toolbar-height: 44px;
  --platform-opacity: 0.8;

}

.ios ion-range {
  --knob-size: 16px;
}
