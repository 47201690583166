.new-track {
  cursor: pointer;
}

img.logoSmall{
	display: none;
}

@media (max-width: 600px) {
	img.logo{
		display: none;
	}
	img.logoSmall{
		display: block;
	}
}

ion-toolbar{
	
	 /* --background: transparent; */
	/* --ion-color-base: transparent !important; */
	/* border-bottom:2px solid #00923f !important;  */
	border-bottom: 3px solid #ffffff23;
}

/*ion-content{
	 background-color: #12263b !important; 
} */

ion-card{
	display: flex;
	flex-direction: column;
	width: 100% !important;
	height:100% !important;
	margin: 0 !important;
}

ion-button.menu {
	color:white;
	font-size: 1em !important;
	font-weight: bolder !important;
	padding-top: 2px;
}
ion-button.selected.menu {
	color:green; 
	/* color:orangered; */
}
.light-background {
	background-color: rgba(255, 255, 255, .1);
}
/* .gradient-background {	
	background: rgb(38,38,39) !important; 
	 background: rgb(18,38,59) !important; 
	
	background: -moz-linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	background: -webkit-linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	background: linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#12263b",endColorstr="#0b1a2a",GradientType=1) !important;
} */
.fifpadding-bot {
	padding-bottom: 30px;
}
.background-ago {
	background-repeat: repeat;
}
.content-spacing {
	padding-left: 20px;
	padding-top: 10px;
}
#main-content {
	--padding-bottom: 0px !important;
}
.toolbar-container {
	display: block!important;
}
.display-block {
	display: block !important;
}