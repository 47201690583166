.vjs-theme-city {
    --vjs-theme-city--primary: #6fb04e;
    --vjs-theme-city--secondary: #fff
}

.vjs-theme-city .vjs-control-bar {
    height: 100px;
    padding-top: 50px;
    padding-right: 15px;
    left: 0px;
    background: none;
    background-image: linear-gradient(0deg, #41a337, transparent)
}

.vjs-theme-city .vjs-button>.vjs-icon-placeholder:before {
    line-height: 50px
}

.vjs-theme-city .vjs-play-progress:before {
    display: none
}

.vjs-theme-city .vjs-progress-control {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 40px
}

.vjs-theme-city .vjs-progress-control .vjs-progress-holder {
    position: absolute;
    top: 20px;
    right: 0;
    left: 0;
    width: 100%;
    margin: 0
}

.vjs-theme-city .vjs-play-progress {
    background-color: var(--vjs-theme-city--primary)
}

.vjs-theme-city .vjs-remaining-time {
    order: 1;
    line-height: 50px;
    flex: 3;
    text-align: left
}

.vjs-theme-city .vjs-play-control {
    order: 2;
    flex: 8;
    font-size: 2.0em
}
.vjs-theme-city .vjs-fullscreen-control {
    font-size: 2.0em;
}
.vjs-theme-city .vjs-audio-button {
    font-size: 1.3em;
}

.vjs-theme-city .vjs-fullscreen-control, .vjs-theme-city .vjs-picture-in-picture-control, .vjs-theme-city .vjs-volume-panel {
    order: 3;
    flex: 1;
}

.vjs-theme-city .vjs-volume-panel:hover .vjs-volume-control.vjs-volume-horizontal {
    height: 100%
}

.vjs-theme-city .vjs-mute-control {
    display: none
}

.vjs-theme-city .vjs-volume-panel {
    margin-left: .5em;
    margin-right: .5em;
    padding-top: 1.5em
}

.vjs-theme-city .vjs-volume-bar.vjs-slider-horizontal, .vjs-theme-city .vjs-volume-panel, .vjs-theme-city .vjs-volume-panel.vjs-volume-panel-horizontal:hover, .vjs-theme-city .vjs-volume-panel:active .vjs-volume-control.vjs-volume-horizontal, .vjs-theme-city .vjs-volume-panel:focus .vjs-volume-control.vjs-volume-horizontal, .vjs-theme-city .vjs-volume-panel:hover, .vjs-theme-city .vjs-volume-panel:hover .vjs-volume-control.vjs-volume-horizontal {
    width: 3em
}

.vjs-theme-city .vjs-volume-level:before {
    font-size: 1em
}

.vjs-theme-city .vjs-volume-panel .vjs-volume-control {
    opacity: 1;
    width: 100%;
    height: 100%
}

.vjs-theme-city .vjs-volume-bar {
    background-color: transparent;
    margin: 0
}

.vjs-theme-city .vjs-slider-horizontal .vjs-volume-level {
    height: 100%
}

.vjs-theme-city .vjs-volume-bar.vjs-slider-horizontal {
    margin-top: 0;
    margin-bottom: 0;
    height: 100%
}

.vjs-theme-city .vjs-volume-bar:before {
    content: "";
    z-index: 0;
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    border-color: transparent transparent hsla(0, 0%, 100%, .25);
    border-style: solid;
    border-width: 0 0 1.75em 3em
}

.vjs-theme-city .vjs-volume-level {
    overflow: hidden;
    background-color: transparent
}

.vjs-theme-city .vjs-volume-level:before {
    content: "";
    z-index: 1;
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    border-left: 3em solid transparent;
    border-bottom: 1.75em solid var(--vjs-theme-city--secondary);
    border-right: 0 solid transparent;
    border-top: 0 solid transparent
}