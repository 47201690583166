/*#cast-button {
    --connected-color: green;
    --disconnected-color: red;
}*/

#cast-button-android {
    position:absolute;
    width: 32px;
    height: 32px;    
    bottom: 5px;
    right: -5px;
}
