@media (min-width: 600px) {
  ion-modal.fullscreen {
    --width: 100%;
    --height: 100%;
    --border-radius: 0;
  }
}
@media (min-width: 1024px) {
  ion-modal.fullscreen {
    --width: 100%;
    --max-width: 890px;
    --height: 100%;
    --border-radius: 0;
  }
  .sc-ion-modal-md-h:first-of-type {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.52);
    --box-shadow: 0 78px 98px rgb(255 255 255 / 30%);
  }
}
.stream-content {
  --padding-start: 0px;
  --padding-end: 0px;
  --overflow: hidden;
  --height:100%;
  user-select: none;
}
.stream-content2 {
  --padding-start: 0px;
  --padding-end: 0px;
  --width:1000px;
  user-select: none;
}

.stream-content img {
  min-width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  margin-top: 24px;
}

.stream-content h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 4px;
}

.stream-content h4 {
  font-size: 0px;
  font-weight: 500;
  margin-top: 0px;
  color: #aaa;
}

.stream-controls {
  display: flex;
  flex-direction: row;
  font-size: 136px;
}

.stream-controls ion-icon {
  flex: 1;
  cursor: pointer;
}

.stream-controls .play-pause {
  font-size: 64px;
}

.stream-progress-stream {
  position: relative;
  height: 2px;
  background-color: #aaa;
}

.stream-progress-current {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #fff;
}

.stream-progress-time {
  display: flex;
  margin-top: 5px;
  font-size: 12px;
}

.stream-progress-time-current {
  flex: 1;
  color: #eee;
}

.stream-progress-time-left {
  color: #aaa;
}
ion-title{
  font-size: 1em;
  padding-left: 10px;
}
.description {
  margin: 0px !important;
  padding-top: 25px !important;
  font-size: 0.75em;
}
.channel-thumbnail {
  height: 48px;
  width: auto;
  max-width: 48px;
  margin: 5px;
  border-radius: 5px;
  display: block;
}
.toolbar-background {
  background: transparent!important;
}
.toolbar-container {
  --color: #fff!important;
}
.background-ch-thumb {
  padding: 5px;
  overflow: hidden;
}
@media (max-width: 860px) {
  .channel-thumbnail {
    height: 33px;
    width: auto;
    max-width: 33px;
    margin: 5px;
    border-radius: 5px;
    display: block;
  }
  .background-ch-thumb {
    padding: 10px;
    overflow: hidden;
  }
}
.background-ch-thumb-inner {
  background-color: #fff;
  border-radius: 5px;
  display: block;
  overflow: hidden;
}

.related-channels ion-item, ion-content.related-channels {
	--background: transparent;
}
.ago-background {
	--background: rgb(18,38,59) !important;
	--background: -moz-linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	--background: -webkit-linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	--background: linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
  background: rgb(18,38,59) !important;
  background: -moz-linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
  background: -webkit-linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
  background: linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#12263b",endColorstr="#0b1a2a",GradientType=1) !important;
  --color: #fff!important;
}

.white-background {
  background-color: #fff;
}