img.logoSmall{
	display: none;
}

@media (max-width: 600px) {
	img.logo{
		display: none;
	}
	img.logoSmall{
		display: block;
	}
}
ion-thumbnail {
	min-width: 4rem;
	min-height: 4rem;
	position: relative;
}
ion-thumbnail	img {
	max-width: 3rem;
	min-width: 3rem;
	height: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.header-md::after {
	background-image: none!important;
}
/*
.gradient-background {
	--background: rgb(18,38,59) !important;
	--background: -moz-linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	--background: -webkit-linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	--background: linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	background: rgb(18,38,59) !important;
	background: -moz-linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	background: -webkit-linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	background: linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#12263b",endColorstr="#0b1a2a",GradientType=1) !important;
}*/
.search-page ion-item, .search-page ion-content {
	--background: transparent;
}
ion-button.menu {
	color:white;
	font-size: 1em !important;
	font-weight: bolder !important;
	padding-top: 2px;
}
ion-toolbar {
	display: block!important;
}
ion-label h2, ion-label p {
	color: #fff !important;
}
.channel-badge {
	margin: 0px 5px;
	border-radius: 5px !important;
	position: relative;
	border: 2px solid rgba(255, 255, 255, 0.95);
	padding: 10px;
	margin: 5px;
	position: relative;
}
.channel-badge::before {
	opacity: 0.85;
	background-color: #fff;
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;
}
.item-list {
	padding: 10px 0px;
}
.channel-info {
	margin-left: 15px;
}
.light-background {
	background-color: rgba(255, 255, 255, .1);
}
.height-100 {
	min-height: 100%;
}