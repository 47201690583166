ion-item.past {
  border-left: 8px solid var(--ion-color-primary) !important; 
}

ion-item.past.primary{
    --ion-background-color: var(--ion-color-primary-tint);
}
ion-item.past.secondary{
    --ion-background-color: var(--ion-color-secondary-tint);
}
ion-item.past.tertiary{
    --ion-background-color: var(--ion-color-tertiary-tint);
}
ion-item.past.quaternary{
    --ion-background-color: var(--ion-color-dark-tint);
}
ion-item.past.quinary{
    --ion-background-color: rgb(238, 243, 226);
}
ion-item.past.senary{
    --ion-background-color: rgb(247, 226, 226);
}
ion-label.past.primary p{
  color:var(--ion-color-primary) !important;
}
ion-label.past.secondary p{
  color: var(--ion-color-secondary-contrast) !important;
}
ion-label.past.tertiary p{
  color: var(--ion-color-tertiary) !important;
}
ion-label.past.quaternary p{
  color: var(--ion-color-dark) !important;
}
ion-label.past.quinary p{
  color: yellowgreen !important;
}
ion-label.past.senary p{
  color: brown !important;
}

ion-list.epgList {
  margin-top: 50px;
  background: #273748;
}
ion-item.future {
  --ion-background-color: #273748!important;
  color: #fff;
}
ion-label.future p {
  color: #fff;
}
.sc-ion-modal-md-h {
  height: 100%;
}