.you-page ion-item, .you-page ion-content, .you-page ion-list.list-md {
	--background: transparent;
  background: transparent;
}
ion-label {
  color: #fff;
}
.light-background {
	background-color: rgba(255, 255, 255, .1);
}
.height-100 {
	min-height: 100%;
}
.channel-badge {
	margin: 0px 5px;
	border-radius: 5px !important;
	position: relative;
	border: 2px solid rgba(255, 255, 255, 0.95);
	padding: 10px;
	margin: 5px;
	position: relative;
}
.channel-badge::before {
	opacity: 0.85;
	background-color: #fff;
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;
}
.channel-info {
	margin-left: 15px;
}
.title-text {
  font-size: 1.3em !important;
  text-transform: uppercase;
  padding: 15px 5px 5px 5px;
}
.you-page ion-icon {
  color: #fff;
  margin-right: 10px;
}