/*ion-tab-bar {
	border-top: 2px solid #ffffff23;
	background: rgb(18,38,59) !important;
	background: -moz-linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	background: -webkit-linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	background: linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
}*/
ion-tab-button {
	background-color: transparent!important;
}
.tab-has-icon {
	color: #ffffff;
}
.tab-selected {
	color: green;
}