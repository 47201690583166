
.my-labelEPG {
	font-size: 1em;
	padding: 5px;
}
.epg-loading {
	--background: #222;
	--spinner-color: #fff;
	color: #fff;
}
.noEPG {
	margin: 20px !important;
	text-align:center;
	font-size: 2em;
}

ion-col.epg{
	padding:3px !important;
}
ion-card-subtitle.epg{
	font-size: 1em;
}
ion-card-title.epg{
	font-size: 0.6em;
}
ion-slide.epg{
	border: 1px solid #000000 !important;
	border-radius:5px !important;
	margin:1px;
	background-color: #f0f0f0 !important;
}
.swiper-wrapperEPG{
	width:10000px!important;
}
@media (max-width: 860px) {
	ion-slide.epg{
		width: 150px !important;
		height: 70px !important;
	}
	.cardLabelEPG {
		position:relative;
		bottom:1px;
		font-size: 0.5em;
	}
}
@media (min-width: 860px) {
	ion-slide.epg{
		width: 175px !important;
		height: 70px !important;
	}
	.cardLabelEPG {
		position:relative;
		bottom:10px;
		font-size: 2.75em;
	}
}
.card-imageEPG {
	border-radius:20%!important;
	margin: auto !important;
	overflow: hidden;
}
.ago-background {
	--background: rgb(18,38,59) !important;
	--background: -moz-linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	--background: -webkit-linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	--background: linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	background: rgb(18,38,59) !important;
	background: -moz-linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	background: -webkit-linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	background: linear-gradient(120deg, rgba(18,38,59,1) 0%, rgba(11,26,42,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#12263b",endColorstr="#0b1a2a",GradientType=1) !important;
	--color: #fff!important;
}
ion-segment-button {
	--color: #fff!important;
}
ion-segment-button {
	color:white;
	padding-top: 2px;
}
ion-segment-button:hover {
	color:orangered!important;
	padding-top: 2px;
}
ion-segment-button.segment-button-checked {
	/* color:green; */
	color:orangered;
	font-weight: bold!important;
}