.form .below-form {
  padding: 15px 0;
  text-align: center;
}

.form ion-button {
  margin: 16px 16px 0 16px;
}

.form a {
  display: block;
  margin-bottom: 15px;
}

.form a.create {
  font-size: 18px;
}

.form a:last-child {
  color: #aaa;
  text-decoration: none;
}
/* Works - pass "my-custom-class" in cssClass to increase specificity */
.my-custom-class .alert-wrapper {
  background: #e5e5e5;
}

/* Workaround to make enter submit in login page 
IonButtton type submit not doing */

.submit-enter {
    position: absolute;
    visibility: hidden;
    left: -1000px;
  }