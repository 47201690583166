.my-label {
    font-size: 1.2em;
    text-transform: uppercase;
    padding: 15px 5px 5px 5px;
}
ion-col {
    padding:3px !important;
    overflow: hidden;
    padding: 20px!important;
    position: relative;
}
ion-slide {
    margin: 0px 5px;
    border-radius: 5px !important;
    position: relative;
    border: 2px solid rgba(255, 255, 255, 0.95);
}
ion-slide::before {
    opacity: 0.85;
    background-color: #fff;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
}
.ion-col {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 20px;
}
.swiper-wrapper{
    width:10000px!important;
}

.swiper-slide {
    padding: 10px;
    display: inline-block;
}


@media (max-width: 860px) {
    ion-slide{
        width: 110px !important; 
        height: 110px !important;
    }
    .card-imageMovie {
        width: 110px !important;
        /*height: 150px !important;*/       
    }
    
    .MOVIES{
        width: 110px !important;
        height: 150px !important;
        border: none !important;
        border-radius:0px !important;
        padding: 0px !important;
        background-color: #000000 !important;
    }
    .SERIES{
        width: 110px !important;
        height: 170px !important;
        border: none !important;
        border-radius:0px !important;
        background-color: #000000 !important;
    }
    .serieBadge{
        position:relative;
        top:3px; 
        font-size: 0.75em;
    }
    .cctvBadge{
        position:relative;
        bottom: 40px;
        font-size: 0.5em;
    }
    .card-image {
        width: 100% !important;
        height: 100% !important;
    }
    
    .cardLabel {
        position:relative;
        bottom:5px;
        font-size: 0.5em;
    }
}
@media (min-width: 860px) {
    ion-slide {
        width: 180px !important;
        height: 120px !important;
    } 
    .card-imageMovie {
        width: 150px !important;
        /*height: 230px !important;*/    
        
    }
    .MOVIES{
        width: 150px !important;
        height: 220px !important;        
        border: none !important;
        border-radius:0px !important;
        padding: 0px !important;
        background-color: #000000 !important;
    }
    .SERIES{
        width: 150px !important;
        height: 250px !important;
        border: none !important;
        border-radius:0px !important;
        padding: 0px !important;
        background-color: #000000 !important;
    }
    
    .serieBadge{
        position:relative;
        top: 3px; 
        font-size: 1.0em;
    }
    .cctvBadge{
        position:relative;
        bottom:30px;
        font-size: 1.0em;
    }
    .card-image {
        max-width: 120px;
        width: auto !important;
        height: 100% !important;
        overflow: hidden;
        display: block;
    }
    
    .cardLabel {
        position:relative;
        bottom:5px;
        font-size: 0.75em;
    }
}
.card-image {
    border-radius: 5px!important;
    margin: auto !important;
    overflow: hidden;
}

.card-image img {
    border-radius: 15px !important;
}
.image-preview {
    padding: 15px;
    height: 100%;    
    position: relative;
    z-index: 5;
}
.image-previewTV {
    padding: 15px;
    height: 100%;    
    position: relative;
    z-index: 5;
}
.image-previewMOVIES {
    padding: 0px;
    height: 100%;    
    position: relative;    
    
    z-index: 5;
}
.image-previewSERIES {
    padding: 0px;
    height: 100%;    
    position: relative;     
    z-index: 5;
}
.image-previewCCTV {
    padding: 0px;
    height: 100%;    
    position: relative; 
    z-index: 5;
}